import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const faqPage = () => (
  <Layout>
    <SEO title="FAQ" />
  <div class="container">
  <h1 class="faq-title">Rock The Rink FAQ</h1>
  <div class="faq-container">
  <div class="accordion">
    <label for="tm" class="accordionitem"><h2>Why isn't my city on the schedule? <span class="arrow">&raquo;</span></h2></label>
    <input type="checkbox" id="tm"/>
    <p class="hiddentext">For the most up-to-date schedule information, visit us here. Please note that this site displays confirmed performance dates only.</p>
  </div> 
  <div class="accordion">
    <label for="tn" class="accordionitem"><h2>How and Where can I purchase tickets? <span class="arrow">&raquo;</span></h2></label>
    <input type="checkbox" id="tn"/>
    <p class="hiddentext">Click to <Link to="/">RockTheRink</Link> to see our list of upcoming performances. Then find the show in your area and click on “Get Tickets” for all the specific information you need to order tickets. You may also contact the venue listed for your city for more information. Be sure you purchase tickets only from the links on <Link to="/">RockTheRink</Link> , or through your local venue’s website. Secondhand distributors are NOT official ticketing sites. Tickets purchased through these resellers and unofficial ticketing sites may not be valid. If you have questions, call your local venue's box office</p>
  </div>
  <div class="accordion">
    <label for="tp" class="accordionitem"><h2>Can children come to Rock The Rink? <span class="arrow">&raquo;</span></h2></label>
    <input type="checkbox" id="tp"/>
    <p class="hiddentext">Absolutely. The show was created for families of all ages. Please ask your local box office if you have a specific question.</p>
  </div>
  <div class="accordion">
    <label for="tq" class="accordionitem"><h2>Do you offer any special ticket pricing for large groups? <span class="arrow">&raquo;</span></h2></label>
    <input type="checkbox" id="tq"/>
    <p class="hiddentext">Yes! You can gain additional information by contacting your local box office.</p>
  </div>
  <div class="accordion">
    <label for="tr" class="accordionitem"><h2>What time should I arrive? <span class="arrow">&raquo;</span></h2></label>
    <input type="checkbox" id="tr"/>
    <p class="hiddentext">Please make sure to allow time for traffic, parking and entrance delays. Once inside the venue you may want to purchase merchandise or refreshments before being seated at the start of the show.</p>
  </div>
  <div class="accordion">
    <label for="ts" class="accordionitem"><h2>Can I bring a camera? <span class="arrow">&raquo;</span></h2></label>
    <input type="checkbox" id="ts"/>
    <p class="hiddentext">Yes!  In fact, we encourage it.  You should take as many pictures and videos as you like and post them to your social media using #RockTheRink</p>
  </div>
  <div class="accordion">
    <label for="tt" class="accordionitem"><h2>Who can I contact about show merchandise or a question regarding Rock The Rink? <span class="arrow">&raquo;</span></h2></label>
    <input type="checkbox" id="tt"/>
    <p class="hiddentext">You can contact the show directly at <a href="mailto:info@rocktherink.com">info@rocktherink.com</a>.</p>
  </div>
</div> 
</div> 
    
  </Layout>
)


export default faqPage
